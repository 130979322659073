<template>
    <div class="nav">
        <div class="empty"></div>
        <div class="content">
            <div class="dropdown">
                <el-dropdown trigger="click" @command="returnTop">
                    <span class="el-dropdown-link">
                        <i class="icon-dropdown el-icon-s-unfold"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item command="#introduce">{{$t("introduce")}}</el-dropdown-item>
                            <el-dropdown-item command="#clubProfile">{{$t("clubProfile")}}</el-dropdown-item>
                            <el-dropdown-item command="#attentionClub">{{$t("attentionClub")}}</el-dropdown-item>
                            <el-dropdown-item command="#applyForMembership1">{{$t("applyForMembership1")}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>
            </div>
            <div class="logo">
                <img src="@/assets/images/big-logo.png">
            </div>
            <div class="login">
                <div @click="login">
                    <i class="el-icon-user-solid" />
                    <span class="login-btn">{{$t("login")}}</span>
                </div>
                <van-field v-model="value" right-icon="arrow-down" readonly @click="picker.show = true" />
            </div>
        </div>
        <van-popup v-model:show="picker.show" round position="bottom">
            <van-picker :columns="picker.columns" @cancel="picker.show = false" @confirm="valueChange" />
        </van-popup>
    </div>
</template>
<script>
import { useStore } from 'vuex'
import { toRefs, reactive, defineComponent, onMounted } from 'vue'
export default defineComponent({
    name: 'Nav',
    emits: {
        login: (v) => {
            return v
        }
    },
    setup(props, context) {
        const state = reactive({
            value: 'zh',
            picker: {
                show: false,
                columns: ['zh', 'en']
            }
        })
        const store = useStore()
        onMounted(() => {
            state.value = store.getters.locale
        })
        function returnTop(id) {
            document.querySelector(id).scrollIntoView(true)
        }
        function login() {
            context.emit('login')
        }
        function valueChange(value) {
            state.value = value
            state.picker.show = false
            store.commit('setLocale', value)
        }
        return { ...toRefs(state), returnTop, login, valueChange }
    }
})
</script>
<style lang="scss">
.nav {
    height: 60px;
    width: 100%;
    .empty {
        height: 100%;
        width: 100%;
    }
    .content {
        background-color: #253238;
        position: fixed;
        top: 0;
        left: 0;
        width: calc(100% - 20px);
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;
        z-index: 10;
        padding: 0 10px;
        .dropdown {
            .icon-dropdown {
                font-size: 26px;
                color: #fff;
            }
        }
        .logo {
            img {
                display: block;
                width: 70px;
                height: 40px;
            }
        }
        .login {
            display: flex;
            align-items: center;
            cursor: pointer;
            &-btn {
                margin-left: 6px;
                white-space: nowrap;
            }
            .el-icon-user-solid {
                font-size: 18px;
            }
            .el-dropdown {
                margin: 0 10px;
                padding-right: 20px;
            }
            .van-cell {
                margin-left: 10px;
                width: 56px;
                padding: 0 8px;
            }
        }
    }
}
</style>
