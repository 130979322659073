<template>
    <el-row class="wechat" id="attentionClub">
        <img src="@/assets/images/index-belt.jpg" class="bg">
        <el-row class="content1 hidden-xs-only">
            <el-col :span="24">
                <p class="title">{{$t("attentionClub")}}</p>
                <p class="des">{{$t("weChat")}}</p>
                <p class="des">{{$t("facebbok")}}</p>
            </el-col>
        </el-row>
        <el-row class="content2 hidden-sm-and-up">
            <el-col :span="18" :offset="5">
                <p class="title">{{$t("attentionClub")}}</p>
                <p class="des">{{$t("weChat")}}</p>
                <p class="des">{{$t("facebbok")}}</p>
            </el-col>
        </el-row>
    </el-row>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
  setup() {
    const state = reactive({})
    return { ...toRefs(state) }
  },
})
</script>

<style lang="scss" scoped>
.wechat {
  position: relative;
  .bg {
    width: 100%;
    transform: skewY(3deg) translateZ(0);
  }
  .content1 {
    position: absolute;
    color: #fff;
    top: 50%;
    left: 20%;
    transform: translateY(-50%);
    &::before {
      content: 'JOIN US';
      position: absolute;
      z-index: -1;
      top: 10%;
      font-size: 48px;
      font-weight: 800;
      color: rgba(189, 189, 189, 0.16);
    }
    p {
      font-size: 24px;
      margin-top: 10px;
    }
    .title {
      font-weight: 500;
      padding-bottom: 20px;
    }
  }
}
.content2 {
  position: absolute;
  color: #fff;
  top: -40px;
  right: 0;
  //   transform: translateY(-50%);
  text-align: right;

  p {
    font-size: 20px;
    margin-top: 10px;
  }
  .title {
    font-weight: 500;
    padding-bottom: 20px;
    color: #95c051;
    position: relative;
    &::before {
      content: 'JOIN US';
      position: absolute;
      z-index: 1;
      top: 10%;
      right: 0;
      font-size: 48px;
      font-weight: 800;
      color: rgba(189, 189, 189, 0.16);
    }
  }
}
</style>
<style lang="scss" scoped>
@media (max-width: 768px) {
  .wechat {
    top: -20px;
    .bg {
      height: 180px;
    }
  }
}
@media (min-width: 768px) {
  .wechat {
    top: -50px;
    .bg {
      height: 380px;
    }
  }
}
@media (min-width: 1200px) {
  .wechat {
    .bg {
      height: 610px;
    }
  }
}
</style>
