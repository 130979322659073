<template>
    <div class="generalSituation" id="clubProfile">
        <p class="title">{{$t("introductionTitle")}}</p>
        <el-row class="container" :gutter="20">
            <el-col :span="11" :xs="24" class="container-item">
                <img class="img" src="@/assets/images/index-news1.jpg">
                <span class="des">{{$t("introduction2")}}</span>
            </el-col>
            <el-col :span="11" :xs="24" class="container-item">
                <img class="img" src="@/assets/images/index-news2.jpg">
                <span class="des">{{$t("introduction3")}}</span>
            </el-col>
        </el-row>
    </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
  setup() {
    const state = reactive({})
    return { ...toRefs(state) }
  },
})
</script>

<style lang="scss" scoped>
.generalSituation {
  background-color: #f0f3ec;
  padding: 40px 40px 80px;
  .title {
    color: #95c051;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  .container {
    display: flex;
    justify-content: space-between;
    &-item {
      position: relative;
      margin-bottom: 40px;

      > .img {
        display: block;
        width: 100%;
      }
      > .des {
        display: block;
        margin-top: 20px;
        line-height: 22px;
        &::after {
          content: '';
          height: 2px;
          position: absolute;
          top: 104%;
          left: 10px;
          width: 120px;
          background-color: #479cea;
        }
      }
    }
  }
}
</style>
