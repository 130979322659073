<template>
    <div>
        <Nav @login="login" />
        <Carousel />
        <GeneralSituation />
        <Wechat />
        <Benefits @application="application" />
        <Footer />
        <Login :show="show" :registerNum="registerNum" />
    </div>
</template>

<script lang="ts">
import Carousel from './components/Carousel.vue'
import GeneralSituation from './components/GeneralSituation.vue'
import Wechat from './components/Wechat.vue'
import Benefits from './components/Benefits.vue'
import Footer from './components/Footer.vue'
import Login from './components/Login.vue'
import Nav from './components/Nav.vue'
import { defineComponent, reactive, toRefs, onMounted } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
    name: 'Detail',
    setup() {
        const store = useStore()
        const state = reactive({
            show: 0,
            registerNum: 0
        })
        function login() {
            state.show++
        }
        function application() {
            state.registerNum++
        }
        onMounted(() => {
            const width = window.innerWidth
            store.commit('setWidth', width)
        })
        return {
            ...toRefs(state),
            login,
            application
        }
    },
    components: {
        Carousel,
        GeneralSituation,
        Wechat,
        Benefits,
        Footer,
        Login,
        Nav
    }
})
</script>
<style>
</style>