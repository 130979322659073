<template>
    <footer>
        <el-row class="footer">
            <el-col class="content" :xs="{span: 22, offset:1}" :sm="{span: 16, offset: 4}">
                <div class="contactUs">
                    <p class="title">{{$t("contactUs")}}</p>
                    <p class="email">
                        <img src="@/assets/images/email.png">
                        <!-- <span>{{$t("emailAddress")}}</span> -->
                        <span>SCGolfclub.sg@gmail.com</span>
                    </p>
                </div>
                <div class="copyRight">
                    <div class="image">
                        <img src="@/assets/images/technical-support-logo.png">
                        <img src="@/assets/images/big-logo.png">
                    </div>
                    <p class="des">© 2020 All Rights Reserved Terms of Use and Privacy Policy</p>
                </div>
            </el-col>
        </el-row>
    </footer>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
    setup() {
        const state = reactive({})
        return { ...toRefs(state) }
    }
})
</script>

<style lang="scss" scoped>
footer {
    margin-top: 60px;
    width: 100%;
    // height: 80px;
    background-color: #253238;
    // padding-bottom: 20px;
    .footer {
        height: 100%;
        align-items: center;
        .content {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            .contactUs {
                > .title {
                    color: #fff;
                    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
                    padding-bottom: 10px;
                    margin-bottom: 10px;
                    font-weight: bold;
                }
                > .email {
                    display: flex;
                    align-items: center;
                    color: rgba(255, 255, 255, 0.7);
                    font-size: 12px;
                    > img {
                        width: 26px;
                        height: 22px;
                        margin-right: 10px;
                    }
                }
            }
            .copyRight {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: 20px;
                > .image {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    > img {
                        width: 66px;
                        height: 40px;
                        &:nth-of-type(1) {
                            margin-right: 10px;
                        }
                    }
                }
                > .des {
                    color: rgba(255, 255, 255, 0.7);
                    font-size: 12px;
                }
            }
        }
    }
}
</style>
