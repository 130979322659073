
import {
  defineComponent,
  reactive,
  toRefs,
  watch,
  computed,
  getCurrentInstance,
  onMounted
} from 'vue'
import i18n from '@/lang'
import {
  loginToAdmin,
  memRegister,
  sendEmail,
  renewPasswordByVerifCode,
  imageUpload
} from '@/api/user'
import { ElMessage } from 'element-plus'
import { Dialog } from 'vant'
import { useStore } from 'vuex'
export default defineComponent({
  props: {
    show: {
      type: Number
    },
    registerNum: {
      type: Number
    }
  },
  setup(props) {
    const store = useStore()
    const state = reactive({
      visible: false,
      activeName: 'Login',
      isForget: false,
      saveInfo: true,
      form1: {
        memberId: '',
        // email: '',
        password: ''
      },
      form2: {
        email: '',
        mobileNum: '',
        nickname: '',
        surname: '',
        givenName: '',
        handicapNum: '',
        password: '',
        gender: '',
        birthDateStr: '',
        confirmPassword: '',
        ecPerson: '',
        ecNum: '',
        shirtSize: '',
        nationality: '',
        businessType: '',
        profilePicture: ''
      },
      form3: {
        email: '',
        code: '',
        password: '',
        newPassword: '',
        time: 0
      },
      gender: {
        en: [
          { label: 'Male', value: 'M' },
          { label: 'Female', value: 'F' }
        ],
        zh: [
          { label: '男', value: 'M' },
          { label: '女', value: 'F' }
        ],
        show: false,
        value: ''
      },
      shirtSize: {
        show: false,
        list: ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL']
      },
      birth: {
        show: false,
        minDate: new Date('1900/01/01 00:00:00'),
        value: ''
      }
    })
    watch(
      () => [props.show],
      () => {
        state.visible = true
        state.activeName = 'Login'
      }
    )
    watch(
      () => [props.registerNum],
      () => {
        state.visible = true
        state.activeName = 'Register'
      }
    )
    const dom = getCurrentInstance()
    const ctx = (dom as any).ctx

    function getInfoFn() {
      const info = JSON.parse(localStorage.getItem('userInfo'))
      if (info) {
        state.form1 = info
      }
    }
    function saveInfoFn() {
      if (state.saveInfo) {
        const info = JSON.stringify(state.form1)
        localStorage.setItem('userInfo', info)
      } else {
        localStorage.setItem('userInfo', JSON.stringify(null))
      }
    }
    function login() {
      const form11 = (dom as any).refs.form11
      form11.validate().then(async () => {
        const data = await loginToAdmin(state.form1)
        if ((data as any).code === '1') {
          ElMessage.success(data.data.message)

          localStorage.setItem('accessToken', data.data.accessToken)
          saveInfoFn()
          //跳转
          const isStaging = process.env.VUE_APP_ENV === 'staging'
          const isProd = process.env.VUE_APP_ENV === 'production'
          if (isStaging) {
            location.replace(location.origin + '/golf')
          }
          if (isProd) {
            location.replace(location.origin + '/customer')
          }
        } else {
          ElMessage.error(data.data.message)
        }
      })
    }
    function reLogin() {
      state.activeName = 'Login'
      state.isForget = false
    }
    function dateFormat(fmt: string, date: Date) {
      let ret
      const opt: any = {
        'Y+': date.getFullYear().toString(),
        'm+': (date.getMonth() + 1).toString(),
        'd+': date.getDate().toString(),
        'H+': date.getHours().toString(),
        'M+': date.getMinutes().toString(),
        'S+': date.getSeconds().toString()
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
          )
        }
      }
      return fmt
    }
    function register() {
      const form22 = (dom as any).refs.form22
      form22.validate().then(async () => {
        const param = { ...state.form2 }
        param.birthDateStr = dateFormat(
          'dd/mm/YYYY',
          new Date(state.form2.birthDateStr)
        )
        const data = await memRegister(param)
        if ((data as any).code === '1') {
          Dialog({
            message: data.data.message,
            confirmButtonText: ctx.$t('confirm'),
            confirmButtonColor: '#95c051'
          }).then(() => {
            reLogin()
          })
          // state.form1.email = param.email
          // state.form1.password = param.password
        } else {
          ElMessage.error(data.data.message)
        }
      })
    }
    function forgetPassword() {
      state.isForget = true
    }
    let interval1: any = null
    async function sendOtp() {
      if (!state.form3.email) {
        ElMessage.error(ctx.$t('errorEmail'))
      } else {
        const data = await sendEmail({ email: state.form3.email })
        if ((data as any).code === '1') {
          ElMessage.success(data.data.message)
          state.form3.time = 60
          interval1 = setInterval(() => {
            state.form3.time--
            if (state.form3.time <= 0) {
              clearInterval(interval1)
            }
          }, 1000)
        } else {
          ElMessage.error(data.data.message)
        }
      }
    }
    function resetPassword() {
      const form33 = (dom as any).refs.form33
      form33.validate().then(async () => {
        const param = {
          code: state.form3.code,
          email: state.form3.email,
          newPassword: state.form3.newPassword
        }
        const data = await renewPasswordByVerifCode(param)
        if ((data as any).code === '1') {
          ElMessage.success(data.data.message)
          // state.form1.email = param.email
          // state.form1.password = param.newPassword
          reLogin()
        } else {
          ElMessage.error(data.data.message)
        }
      })
    }
    function back() {
      if (state.activeName === 'Login' && state.isForget) {
        state.isForget = false
        return
      }
      state.visible = false
    }
    function getText(params: string) {
      return i18n.global.t(params)
    }
    function checkConfirmPassword1(value) {
      return value && state.form2.password === value
    }
    function checkConfirmPassword2(value) {
      return value && state.form3.password === value
    }
    function checkEcNum(value) {
      const reg = /^[0-9]{8,11}$/g
      return reg.test(value)
    }
    const allRules = computed(() => {
      return {
        memberId: [
          {
            required: true,
            message: getText('errorMemberId'),
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: getText('errorEmail'),
            validator(value) {
              const emailReg = /^([A-Za-z0-9_-].+)+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
              return emailReg.test(value)
            }
          }
        ],
        mobileNum: [
          {
            required: true,
            message: getText('errorMobileNum'),
            validator(value) {
              const phoneReg = /^[0-9]{8,11}$/g
              return phoneReg.test(value)
            }
          }
        ],
        password: [
          {
            required: true,
            message: getText('errorPassword1'),
            validator(value) {
              return value.length >= 6
            }
          }
        ],
        code: [
          {
            required: true,
            message: getText('errorOtp'),
            validator(value) {
              const codeReg = /^[1-9]\d*$/g
              return codeReg.test(value)
            }
          }
        ],
        // nickname: [
        //   {
        //     required: true,
        //     message: getText('errorNickname')
        //   }
        // ],
        surname: [
          {
            required: true,
            message: getText('errorSurname')
          }
        ],
        givenName: [
          {
            required: true,
            message: getText('errorGivenName')
          }
        ],
        handicapNum: [
          {
            required: true,
            message: getText('errorHandicapNum'),
            trigger: 'blur'
          }
        ],
        gender: [
          {
            required: true,
            message: getText('errorGender')
          }
        ],
        birthDateStr: [
          {
            required: true,
            message: getText('errorBirth')
          }
        ],
        confirmPassword: [
          {
            required: true,
            message: getText('errorPassword2'),
            validator: checkConfirmPassword1
          }
        ],
        newPassword: [
          {
            required: true,
            message: getText('errorPassword2'),
            validator: checkConfirmPassword2
          }
        ],
        shirtSize: [
          {
            required: true,
            message: getText('errorShirtSize')
          }
        ],
        nationality: [
          {
            required: true,
            message: getText('errorNationality')
          }
        ],
        businessType: [
          {
            required: true,
            message: getText('errorBusinessType')
          }
        ],
        ecPerson: [
          {
            required: true,
            message: getText('errorEcPerson')
          }
        ],
        ecNum: [
          {
            required: true,
            message: getText('errorEcNum'),
            validator: checkEcNum
          }
        ]
      }
    })

    const genderList = computed(() => {
      return state.gender[ctx.$i18n.locale].map(i => {
        return i.label
      })
    })
    function genderConfirm(v, index) {
      state.form2.gender = state.gender[ctx.$i18n.locale][index].value
      state.gender.value = v
      state.gender.show = false
    }
    function shirtSizeConfirm(shirtSize) {
      state.form2.shirtSize = shirtSize
      state.shirtSize.show = false
    }
    function birthConfirm(v) {
      const v1 = dateFormat('YYYY-mm-dd', new Date(v))
      state.form2.birthDateStr = v1
      state.birth.value = v1
      state.birth.show = false
    }
    onMounted(() => {
      const memberIdStr = store.getters.memberIdStr
      if (memberIdStr) {
        state.form1.memberId = memberIdStr
        state.visible = true
        store.commit('setMemberIdStr', '')
      }
      getInfoFn()
    })

    const form1Disabled = computed(() => {
      let disabled = false
      for (const i in state.form1) {
        if (!state.form1[i] && !disabled) {
          disabled = true
        }
      }
      return disabled ? '#8FA7B2' : '#95c051'
    })
    const form2Disabled = computed(() => {
      let disabled = false
      for (const i in state.form2) {
        if (!state.form2[i] && !disabled) {
          disabled = true
        }
      }
      return disabled ? '#8FA7B2' : '#95c051'
    })
    const form3Disabled = computed(() => {
      const { email, code, password, newPassword } = state.form3
      const form3 = { email, code, password, newPassword }
      let disabled = false
      for (const i in form3) {
        if (!form3[i] && !disabled) {
          disabled = true
        }
      }
      return disabled ? '#8FA7B2' : '#95c051'
    })
    const VUE_APP_IMAGE_URL = process.env.VUE_APP_IMAGE_URL
    function handleChange(file: any) {
      const param = new FormData()
      param.append('file', file.file)
      imageUpload(param).then(res => {
        console.log(res)
        const { data } = res
        if (data && data.success) {
          const { filePath } = data
          state.form2.profilePicture = filePath
        }
      })
    }
    return {
      ...toRefs(state),
      watch,
      reLogin,
      forgetPassword,
      back,
      getText,
      allRules,
      login,
      register,
      sendOtp,
      resetPassword,
      genderList,
      genderConfirm,
      shirtSizeConfirm,
      birthConfirm,
      form1Disabled,
      form2Disabled,
      form3Disabled,
      saveInfoFn,
      getInfoFn,
      VUE_APP_IMAGE_URL,
      handleChange
    }
  }
})
