<template>
    <el-row class="benefits" id="applyForMembership1">
        <el-col class="content" :xs="{span: 20, offset: 4}" :sm="{span: 20, offset: 4}">
            <p class="title">{{$t("memberBenefits")}}</p>
            <p class="item">{{$t("benefits1")}}</p>
            <p class="item">{{$t("benefits2")}}</p>
            <p class="item">{{$t("benefits3")}}</p>
            <p class="item">{{$t("benefits4")}}</p>
            <p class="item">{{$t("benefits5")}}</p>
            <p class="item">{{$t("benefits6")}}</p>
            <p class="item">{{$t("benefits7")}}</p>
            <p class="item">{{$t("benefits8")}}</p>
            <el-button round class="membership" @click="application">{{$t("applyForMembership1")}}</el-button>
        </el-col>
        <el-col class="df" :xs="{span: 16, offset: 4}" :sm="{span: 16, offset: 4}">
            <div class="image image1">
                <img src="@/assets/images/index-info1.jpg">
            </div>
            <div class="image image2">
                <img src="@/assets/images/index-info2.jpg">
            </div>
            <div class="image image3">
                <img src="@/assets/images/index-info3.jpg">
            </div>
            <div class="image image3">
                <img src="@/assets/images/index-info4.jpg">
            </div>
            <div class="image image2">
                <img src="@/assets/images/index-info5.jpg">
            </div>
        </el-col>
    </el-row>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
    setup(prop, ctx) {
        const state = reactive({})
        function application() {
            ctx.emit('application')
        }
        return { ...toRefs(state), application }
    }
})
</script>

<style lang="scss" scoped>
.benefits {
    .content {
        .title {
            margin-bottom: 20px;
            font-weight: bold;
        }
        .item {
            margin-bottom: 10px;
        }
        .membership {
            background-color: #95c051;
            color: #fff;
            margin-top: 10px;
        }
    }
    .df {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        img {
            width: 100%;
            height: 100%;
            //   display: block;
            //   margin-top: 20px;
        }
        .image {
            margin-top: 20px;
        }
        .image1 {
            width: 100%;
        }
        .image2 {
            width: 60%;
        }
        .image3 {
            width: 38%;
        }
    }
}
</style>
<style lang="scss" scoped>
@media (max-width: 767px) {
    .benefits {
        .content {
            .title {
                font-size: 14px;
            }
            .item,
            .membership {
                font-size: 12px;
            }
        }
        .df {
            .image2 {
                height: 150px;
            }
            .image3 {
                height: 150px;
            }
        }
    }
}
@media (min-width: 767px) {
    .benefits {
        .content {
            .title {
                font-size: 18px;
            }
            .item,
            .membership {
                font-size: 16px;
            }
        }
        .df {
            .image2 {
                height: 300px;
            }
            .image3 {
                height: 300px;
            }
        }
    }
}
</style>