<template>
    <div class="carousel " id="introduce">
        <el-carousel :autoplay="false" arrow="never">
            <el-carousel-item>
                <el-row class="content" :gutter="24">
                    <el-col class="des" :span="12" :xs="20">
                        <span class="welcome">{{$t("welcome")}}</span>
                        <span>{{ $t("introduction1") }}</span>
                    </el-col>
                    <img class="big-banner" src="@/assets/images/big-banner.jpg" />
                </el-row>
            </el-carousel-item>
            <el-carousel-item>
                <el-row class="content" :gutter="24">
                    <el-col class="des" :span="12" :xs="20">
                        <span>{{ $t("introduction1") }}</span>
                    </el-col>
                    <img class="big-banner" src="@/assets/images/big-banner.jpg" />
                </el-row>
            </el-carousel-item>
            <el-carousel-item>
                <el-row class="content" :gutter="24">
                    <el-col class="des" :span="12" :xs="20">
                        <span>{{ $t("introduction1") }}</span>
                    </el-col>
                    <img class="big-banner" src="@/assets/images/big-banner.jpg" />
                </el-row>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
  name: 'Carousel',
  setup() {
    const state = reactive({})

    return { ...toRefs(state) }
  },
})
</script>

<style lang="scss" scoped>
.carousel {
  position: relative;
  .el-carousel {
    :deep(.el-carousel__container) {
      z-index: 3;
      height: 100%;
    }
    :deep(.el-carousel__indicators) {
      z-index: 3;
    }
  }
  .content {
    position: relative;
    height: 100%;
    &::after {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      content: '';
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
    }
    .des {
      position: absolute;
      z-index: 1;
      top: 30%;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      width: 100%;
      > span {
        color: #fff;
      }
      .welcome {
        font-weight: 500;
        color: #479cea;
      }
    }
    .big-banner {
      display: block;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
</style>
<style lang="scss" scoped>
@media (max-width: 768px) {
  .el-carousel {
    height: 400px;
    .content {
      .des {
        span {
          line-height: 18px;
          font-size: 12px;
          margin-top: 10px;
        }
        .welcome {
          font-size: 20px;
        }
      }
    }
  }
}
@media (min-width: 767px) {
  .el-carousel {
    height: 610px;
    .content {
      .des {
        span {
          line-height: 28px;
          font-size: 18px;
          margin-top: 20px;
        }
        .welcome {
          font-size: 36px;
        }
      }
    }
  }
}
</style>
