
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
  name: 'Carousel',
  setup() {
    const state = reactive({})

    return { ...toRefs(state) }
  },
})
