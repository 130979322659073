
import { defineComponent, reactive, toRefs } from 'vue'

export default defineComponent({
    setup(prop, ctx) {
        const state = reactive({})
        function application() {
            ctx.emit('application')
        }
        return { ...toRefs(state), application }
    }
})
