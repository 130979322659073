<template>
  <div class="login">
    <van-dialog
      v-model:show="visible"
      :showConfirmButton="false"
      class="dialog"
    >
      <el-row class="header">
        <el-col class="header-item" :span="24">
          <div class="back" @click.stop="back">
            <i class="el-icon-arrow-left" /> {{ $t('back') }}
          </div>
          <div class="login">
            <el-tabs v-model="activeName">
              <el-tab-pane :label="$t('login')" name="Login"></el-tab-pane>
              <el-tab-pane
                :label="$t('register')"
                name="Register"
              ></el-tab-pane>
            </el-tabs>
          </div>
        </el-col>
      </el-row>
      <el-row class="dialog-row">
        <el-col class="content" :span="24">
          <van-form
            ref="form11"
            @submit="login"
            v-show="activeName === 'Login' && !isForget"
          >
            <!-- <span class="field-label">{{$t('pEmail')}}</span>
                        <van-field v-model="form1.email" name="email" :placeholder="$t('pEmail')" :rules="allRules.email" autocomplete="on" maxlength="50" /> -->
            <span class="field-label">{{ $t('pMemberId') }}</span>
            <van-field
              v-model="form1.memberId"
              name="memberId"
              :placeholder="$t('pMemberId')"
              :rules="allRules.memberId"
              autocomplete="on"
              maxlength="10"
            />
            <span class="field-label">{{ $t('pPassword') }}</span>
            <van-field
              v-model="form1.password"
              name="password"
              type="password"
              :placeholder="$t('pPassword')"
              :rules="allRules.password"
              autocomplete="on"
              maxlength="20"
            />
            <van-checkbox
              v-model="saveInfo"
              shape="square"
              style="margin-top:10px;"
              >{{ $t('saveInfomation') }}</van-checkbox
            >
            <div style="margin: 16px;">
              <van-button
                :color="form1Disabled"
                block
                type="primary"
                native-type="submit"
              >
                {{ $t('login') }}
              </van-button>
            </div>
            <div class="reLogin">
              <span @click.stop="forgetPassword">{{
                $t('forgetPassword')
              }}</span>
            </div>
          </van-form>
          <van-form
            ref="form22"
            @submit="register"
            v-show="activeName === 'Register'"
          >
            <div class="form22">
              <span class="field-label">{{ $t('pEmail') }}</span>
              <van-field
                v-model="form2.email"
                name="email"
                :placeholder="$t('pEmail')"
                :rules="allRules.email"
                maxlength="50"
              />
              <span class="field-label">{{ $t('pMobileNum') }}</span>
              <van-field
                v-model="form2.mobileNum"
                name="mobileNum"
                :placeholder="$t('pMobileNum')"
                :rules="allRules.mobileNum"
                maxlength="11"
              />
              <!-- <span class="field-label">{{ $t('pNickname') }}</span>
              <van-field
                v-model="form2.nickname"
                name="nickname"
                :placeholder="$t('pNickname')"
                :rules="allRules.nickname"
                maxlength="20"
              /> -->
              <span class="field-label">{{ $t('pSurname') }}</span>
              <van-field
                v-model="form2.surname"
                name="surname"
                :placeholder="$t('pSurname')"
                :rules="allRules.surname"
                maxlength="20"
              />
              <span class="field-label">{{ $t('pGivenName') }}</span>
              <van-field
                v-model="form2.givenName"
                name="givenName"
                :placeholder="$t('pGivenName')"
                :rules="allRules.givenName"
                maxlength="20"
              />
              <span class="field-label">{{ $t('pGender') }}</span>
              <van-field
                v-model="gender.value"
                name="gender"
                :placeholder="$t('pGender')"
                :rules="allRules.gender"
                readonly
                @click="gender.show = true"
              />
              <span class="field-label">{{ $t('pBirth') }}</span>
              <van-field
                v-model="form2.birthDateStr"
                name="birthDateStr"
                :placeholder="$t('pBirth')"
                :rules="allRules.birthDateStr"
                readonly
                @click="birth.show = true"
              />
              <span class="field-label">{{ $t('pHandicapNo') }}</span>
              <van-field
                v-model="form2.handicapNum"
                name="handicapNum"
                :placeholder="$t('errorHandicapNumber')"
                :rules="allRules.handicapNum"
                type="number"
                maxlength="20"
              />
              <span class="field-label">{{ $t('pEcPerson') }}</span>
              <van-field
                v-model="form2.ecPerson"
                name="ecPerson"
                :placeholder="$t('errorEcPerson')"
                :rules="allRules.ecPerson"
                maxlength="20"
              />
              <span class="field-label">{{ $t('pEcNum') }}</span>
              <van-field
                v-model="form2.ecNum"
                name="ecNum"
                :placeholder="$t('errorEcNum')"
                :rules="allRules.ecNum"
                maxlength="20"
              />
              <span class="field-label">{{ $t('pShirtSize') }}</span>
              <van-field
                v-model="form2.shirtSize"
                name="shirtSize"
                :placeholder="$t('errorShirtSize')"
                :rules="allRules.shirtSize"
                readonly
                @click="shirtSize.show = true"
              />

              <span class="field-label">{{ $t('pNationality') }}</span>
              <van-field
                v-model="form2.nationality"
                name="nationality"
                :placeholder="$t('pNationality')"
                :rules="allRules.nationality"
                maxlength="50"
              />
              <span class="field-label">{{ $t('pBusinessType') }}</span>
              <van-field
                v-model="form2.businessType"
                name="businessType"
                :placeholder="$t('pBusinessType')"
                :rules="allRules.businessType"
                maxlength="50"
              />

              <span class="field-label">{{ $t('pPhoto') }}</span>
              <div>
                <van-uploader :after-read="handleChange">
                  <van-image
                    v-if="form2.profilePicture"
                    :src="VUE_APP_IMAGE_URL + form2.profilePicture"
                    width="40"
                    height="40"
                  />
                  <van-button v-else type="primary">{{
                    $t('btnUpload')
                  }}</van-button>
                </van-uploader>
              </div>

              <span class="field-label">{{ $t('pPassword') }}</span>
              <van-field
                v-model="form2.password"
                name="password"
                type="password"
                :placeholder="$t('pPassword')"
                :rules="allRules.password"
                maxlength="20"
              />
              <span class="field-label">{{ $t('pConfirmPassword') }}</span>
              <van-field
                v-model="form2.confirmPassword"
                name="confirmPassword"
                type="password"
                :placeholder="$t('pConfirmPassword')"
                :rules="allRules.confirmPassword"
                maxlength="20"
              />
            </div>
            <div style="margin: 16px;">
              <van-button
                :color="form2Disabled"
                block
                type="primary"
                native-type="submit"
              >
                {{ $t('signUp') }}
              </van-button>
            </div>
            <div class="reLogin" @click="reLogin">
              <span>{{ $t('alreadyAccount') }}</span>
              <span>{{ $t('login') }}</span>
            </div>
          </van-form>
          <van-form
            ref="form33"
            @submit="resetPassword"
            v-show="activeName === 'Login' && isForget"
          >
            <span class="field-label">{{ $t('pEmail') }}</span>
            <van-field
              v-model="form3.email"
              name="email"
              :placeholder="$t('pEmail')"
              :rules="allRules.email"
              maxlength="50"
            >
              <template #button>
                <van-button
                  v-if="form3.time <= 0"
                  @click="sendOtp"
                  size="mini"
                  type="primary"
                  >{{ $t('sendOtp') }}</van-button
                >
                <van-button v-else size="mini" type="primary" disabled
                  >{{ $t('reSend') }}{{ form3.time }}s</van-button
                >
              </template>
            </van-field>
            <span class="field-label">{{ $t('pOtpCode') }}</span>
            <van-field
              v-model="form3.code"
              name="code"
              :placeholder="$t('pOtpCode')"
              :rules="allRules.code"
              maxlength="10"
            />
            <span class="field-label">{{ $t('pPassword') }}</span>
            <van-field
              v-model="form3.password"
              name="password"
              type="password"
              :placeholder="$t('pPassword')"
              :rules="allRules.password"
              maxlength="20"
            />
            <span class="field-label">{{ $t('pConfirmPassword') }}</span>
            <van-field
              v-model="form3.newPassword"
              name="newPassword"
              type="password"
              :placeholder="$t('pConfirmPassword')"
              :rules="allRules.newPassword"
              maxlength="20"
            />
            <div style="margin: 16px;">
              <van-button
                :color="form3Disabled"
                block
                type="primary"
                native-type="submit"
              >
                {{ $t('resetPassword') }}
              </van-button>
            </div>
            <div class="reLogin" @click="reLogin">
              <span>{{ $t('alreadyAccount') }}</span>
              <span>{{ $t('login') }}</span>
            </div>
          </van-form>
        </el-col>
      </el-row>
    </van-dialog>
    <van-popup v-model:show="gender.show" round position="bottom">
      <van-picker
        :columns="genderList"
        @cancel="gender.show = false"
        @confirm="genderConfirm"
      />
    </van-popup>
    <van-popup v-model:show="shirtSize.show" round position="bottom">
      <van-picker
        :columns="shirtSize.list"
        @cancel="shirtSize.show = false"
        @confirm="shirtSizeConfirm"
      />
    </van-popup>
    <van-popup v-model:show="birth.show" round position="bottom">
      <van-datetime-picker
        v-model="birth.value"
        type="date"
        :columns-order="['year', 'month', 'day']"
        @cancel="birth.show = false"
        @confirm="birthConfirm"
        :min-date="birth.minDate"
      />
    </van-popup>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  reactive,
  toRefs,
  watch,
  computed,
  getCurrentInstance,
  onMounted
} from 'vue'
import i18n from '@/lang'
import {
  loginToAdmin,
  memRegister,
  sendEmail,
  renewPasswordByVerifCode,
  imageUpload
} from '@/api/user'
import { ElMessage } from 'element-plus'
import { Dialog } from 'vant'
import { useStore } from 'vuex'
export default defineComponent({
  props: {
    show: {
      type: Number
    },
    registerNum: {
      type: Number
    }
  },
  setup(props) {
    const store = useStore()
    const state = reactive({
      visible: false,
      activeName: 'Login',
      isForget: false,
      saveInfo: true,
      form1: {
        memberId: '',
        // email: '',
        password: ''
      },
      form2: {
        email: '',
        mobileNum: '',
        nickname: '',
        surname: '',
        givenName: '',
        handicapNum: '',
        password: '',
        gender: '',
        birthDateStr: '',
        confirmPassword: '',
        ecPerson: '',
        ecNum: '',
        shirtSize: '',
        nationality: '',
        businessType: '',
        profilePicture: ''
      },
      form3: {
        email: '',
        code: '',
        password: '',
        newPassword: '',
        time: 0
      },
      gender: {
        en: [
          { label: 'Male', value: 'M' },
          { label: 'Female', value: 'F' }
        ],
        zh: [
          { label: '男', value: 'M' },
          { label: '女', value: 'F' }
        ],
        show: false,
        value: ''
      },
      shirtSize: {
        show: false,
        list: ['XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL']
      },
      birth: {
        show: false,
        minDate: new Date('1900/01/01 00:00:00'),
        value: ''
      }
    })
    watch(
      () => [props.show],
      () => {
        state.visible = true
        state.activeName = 'Login'
      }
    )
    watch(
      () => [props.registerNum],
      () => {
        state.visible = true
        state.activeName = 'Register'
      }
    )
    const dom = getCurrentInstance()
    const ctx = (dom as any).ctx

    function getInfoFn() {
      const info = JSON.parse(localStorage.getItem('userInfo'))
      if (info) {
        state.form1 = info
      }
    }
    function saveInfoFn() {
      if (state.saveInfo) {
        const info = JSON.stringify(state.form1)
        localStorage.setItem('userInfo', info)
      } else {
        localStorage.setItem('userInfo', JSON.stringify(null))
      }
    }
    function login() {
      const form11 = (dom as any).refs.form11
      form11.validate().then(async () => {
        const data = await loginToAdmin(state.form1)
        if ((data as any).code === '1') {
          ElMessage.success(data.data.message)

          localStorage.setItem('accessToken', data.data.accessToken)
          saveInfoFn()
          //跳转
          const isStaging = process.env.VUE_APP_ENV === 'staging'
          const isProd = process.env.VUE_APP_ENV === 'production'
          if (isStaging) {
            location.replace(location.origin + '/golf')
          }
          if (isProd) {
            location.replace(location.origin + '/customer')
          }
        } else {
          ElMessage.error(data.data.message)
        }
      })
    }
    function reLogin() {
      state.activeName = 'Login'
      state.isForget = false
    }
    function dateFormat(fmt: string, date: Date) {
      let ret
      const opt: any = {
        'Y+': date.getFullYear().toString(),
        'm+': (date.getMonth() + 1).toString(),
        'd+': date.getDate().toString(),
        'H+': date.getHours().toString(),
        'M+': date.getMinutes().toString(),
        'S+': date.getSeconds().toString()
      }
      for (const k in opt) {
        ret = new RegExp('(' + k + ')').exec(fmt)
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, '0')
          )
        }
      }
      return fmt
    }
    function register() {
      const form22 = (dom as any).refs.form22
      form22.validate().then(async () => {
        const param = { ...state.form2 }
        param.birthDateStr = dateFormat(
          'dd/mm/YYYY',
          new Date(state.form2.birthDateStr)
        )
        const data = await memRegister(param)
        if ((data as any).code === '1') {
          Dialog({
            message: data.data.message,
            confirmButtonText: ctx.$t('confirm'),
            confirmButtonColor: '#95c051'
          }).then(() => {
            reLogin()
          })
          // state.form1.email = param.email
          // state.form1.password = param.password
        } else {
          ElMessage.error(data.data.message)
        }
      })
    }
    function forgetPassword() {
      state.isForget = true
    }
    let interval1: any = null
    async function sendOtp() {
      if (!state.form3.email) {
        ElMessage.error(ctx.$t('errorEmail'))
      } else {
        const data = await sendEmail({ email: state.form3.email })
        if ((data as any).code === '1') {
          ElMessage.success(data.data.message)
          state.form3.time = 60
          interval1 = setInterval(() => {
            state.form3.time--
            if (state.form3.time <= 0) {
              clearInterval(interval1)
            }
          }, 1000)
        } else {
          ElMessage.error(data.data.message)
        }
      }
    }
    function resetPassword() {
      const form33 = (dom as any).refs.form33
      form33.validate().then(async () => {
        const param = {
          code: state.form3.code,
          email: state.form3.email,
          newPassword: state.form3.newPassword
        }
        const data = await renewPasswordByVerifCode(param)
        if ((data as any).code === '1') {
          ElMessage.success(data.data.message)
          // state.form1.email = param.email
          // state.form1.password = param.newPassword
          reLogin()
        } else {
          ElMessage.error(data.data.message)
        }
      })
    }
    function back() {
      if (state.activeName === 'Login' && state.isForget) {
        state.isForget = false
        return
      }
      state.visible = false
    }
    function getText(params: string) {
      return i18n.global.t(params)
    }
    function checkConfirmPassword1(value) {
      return value && state.form2.password === value
    }
    function checkConfirmPassword2(value) {
      return value && state.form3.password === value
    }
    function checkEcNum(value) {
      const reg = /^[0-9]{8,11}$/g
      return reg.test(value)
    }
    const allRules = computed(() => {
      return {
        memberId: [
          {
            required: true,
            message: getText('errorMemberId'),
            trigger: 'blur'
          }
        ],
        email: [
          {
            required: true,
            message: getText('errorEmail'),
            validator(value) {
              const emailReg = /^([A-Za-z0-9_-].+)+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
              return emailReg.test(value)
            }
          }
        ],
        mobileNum: [
          {
            required: true,
            message: getText('errorMobileNum'),
            validator(value) {
              const phoneReg = /^[0-9]{8,11}$/g
              return phoneReg.test(value)
            }
          }
        ],
        password: [
          {
            required: true,
            message: getText('errorPassword1'),
            validator(value) {
              return value.length >= 6
            }
          }
        ],
        code: [
          {
            required: true,
            message: getText('errorOtp'),
            validator(value) {
              const codeReg = /^[1-9]\d*$/g
              return codeReg.test(value)
            }
          }
        ],
        // nickname: [
        //   {
        //     required: true,
        //     message: getText('errorNickname')
        //   }
        // ],
        surname: [
          {
            required: true,
            message: getText('errorSurname')
          }
        ],
        givenName: [
          {
            required: true,
            message: getText('errorGivenName')
          }
        ],
        handicapNum: [
          {
            required: true,
            message: getText('errorHandicapNum'),
            trigger: 'blur'
          }
        ],
        gender: [
          {
            required: true,
            message: getText('errorGender')
          }
        ],
        birthDateStr: [
          {
            required: true,
            message: getText('errorBirth')
          }
        ],
        confirmPassword: [
          {
            required: true,
            message: getText('errorPassword2'),
            validator: checkConfirmPassword1
          }
        ],
        newPassword: [
          {
            required: true,
            message: getText('errorPassword2'),
            validator: checkConfirmPassword2
          }
        ],
        shirtSize: [
          {
            required: true,
            message: getText('errorShirtSize')
          }
        ],
        nationality: [
          {
            required: true,
            message: getText('errorNationality')
          }
        ],
        businessType: [
          {
            required: true,
            message: getText('errorBusinessType')
          }
        ],
        ecPerson: [
          {
            required: true,
            message: getText('errorEcPerson')
          }
        ],
        ecNum: [
          {
            required: true,
            message: getText('errorEcNum'),
            validator: checkEcNum
          }
        ]
      }
    })

    const genderList = computed(() => {
      return state.gender[ctx.$i18n.locale].map(i => {
        return i.label
      })
    })
    function genderConfirm(v, index) {
      state.form2.gender = state.gender[ctx.$i18n.locale][index].value
      state.gender.value = v
      state.gender.show = false
    }
    function shirtSizeConfirm(shirtSize) {
      state.form2.shirtSize = shirtSize
      state.shirtSize.show = false
    }
    function birthConfirm(v) {
      const v1 = dateFormat('YYYY-mm-dd', new Date(v))
      state.form2.birthDateStr = v1
      state.birth.value = v1
      state.birth.show = false
    }
    onMounted(() => {
      const memberIdStr = store.getters.memberIdStr
      if (memberIdStr) {
        state.form1.memberId = memberIdStr
        state.visible = true
        store.commit('setMemberIdStr', '')
      }
      getInfoFn()
    })

    const form1Disabled = computed(() => {
      let disabled = false
      for (const i in state.form1) {
        if (!state.form1[i] && !disabled) {
          disabled = true
        }
      }
      return disabled ? '#8FA7B2' : '#95c051'
    })
    const form2Disabled = computed(() => {
      let disabled = false
      for (const i in state.form2) {
        if (!state.form2[i] && !disabled) {
          disabled = true
        }
      }
      return disabled ? '#8FA7B2' : '#95c051'
    })
    const form3Disabled = computed(() => {
      const { email, code, password, newPassword } = state.form3
      const form3 = { email, code, password, newPassword }
      let disabled = false
      for (const i in form3) {
        if (!form3[i] && !disabled) {
          disabled = true
        }
      }
      return disabled ? '#8FA7B2' : '#95c051'
    })
    const VUE_APP_IMAGE_URL = process.env.VUE_APP_IMAGE_URL
    function handleChange(file: any) {
      const param = new FormData()
      param.append('file', file.file)
      imageUpload(param).then(res => {
        console.log(res)
        const { data } = res
        if (data && data.success) {
          const { filePath } = data
          state.form2.profilePicture = filePath
        }
      })
    }
    return {
      ...toRefs(state),
      watch,
      reLogin,
      forgetPassword,
      back,
      getText,
      allRules,
      login,
      register,
      sendOtp,
      resetPassword,
      genderList,
      genderConfirm,
      shirtSizeConfirm,
      birthConfirm,
      form1Disabled,
      form2Disabled,
      form3Disabled,
      saveInfoFn,
      getInfoFn,
      VUE_APP_IMAGE_URL,
      handleChange
    }
  }
})
</script>

<style lang="scss" scoped>
/deep/ .van-dialog {
  border-radius: 2px;
  padding: 10px 12px 20px;
  top: 50%;
  background: url('../../../assets/images/login-bg.png') no-repeat;
  background-size: cover;
  .van-dialog__header {
    display: none;
  }
}
.dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  .header {
    &-item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #e5e5e5;
      .back {
        margin-top: 15px;
        color: #52a2eb;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;
      }
      .login {
        :deep(.el-tabs__item) {
          font-size: 16px;
        }
      }
    }
  }
  .dialog-row {
    justify-content: center;
    .content {
      .van-form {
        margin-top: 10px;
        .reLogin {
          margin-left: 10px;
          cursor: pointer;
          color: #007bff;
          text-align: center;
        }
        /deep/ .van-cell {
          padding: 0;
          background: none;
          .van-field__body {
            background-color: #fff;
          }
          .van-field__control {
            padding-left: 4px;
            height: 36px;
            line-height: 36px;
          }
        }
        /deep/ .van-field__button {
          margin-top: -5px;
        }
        .field-label {
          height: 36px;
          line-height: 36px;
          font-size: 14px;
          position: relative;
          margin-left: 8px;
          &::before {
            position: absolute;
            right: calc(100% + 2px);
            top: 60%;
            transform: translateY(-50%);
            color: #ee0a24;
            font-size: 14px;
            content: '*';
          }
        }
      }
      .form22 {
        max-height: 400px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}
</style>
